<template>
  <div>
    <el-page-header @back="dynamic" content="归属地数据库" style="margin-bottom: 15px;">
    </el-page-header>
    <el-button type="text">
      <el-button  size="mini" type="primary" @click="dialog = true">添加数据库</el-button>
    </el-button>
    <el-drawer
        title="新增IP地址"
        :visible.sync="dialog"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        size="40%"
    >
      <div class="demo-drawer__content" style="padding: 50px">
        <el-form :model="ipForm">
          <el-form-item label="IP地址" :label-width="formLabelWidth">
            <el-input v-model="ipForm.ip" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="归属地" :label-width="formLabelWidth">
            <el-input v-model="ipForm.add" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="目录类型" :label-width="formLabelWidth">
            <el-select v-model="ipForm.yxs" placeholder="请选择目录类型">
              <el-option label="移动" value="移动"></el-option>
              <el-option label="联通" value="联通"></el-option>
              <el-option label="电信" value="电信"></el-option>
            </el-select>
          </el-form-item>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="addMenu(ipForm)">确定</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-table
        :data="ipData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="ip"
          label="IP地址"
          width="200">
      </el-table-column>
      <el-table-column
          prop="add"
          label="归属地">
      </el-table-column>
      <el-table-column
          prop="yxs"
          label="运营商"
          width="150">
      </el-table-column>
      <el-table-column
          label="操作"
          width="50">
        <template>
          <el-button
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100%; text-align: center; margin-top: 20px">
      <el-pagination
          background
          layout="prev, pager, next"
          :total=pages.total
          :page-size="50"
          @current-change="getIpByPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "relo",
  data() {
    return {
      formLabelWidth: '80px',
      ipData: [],
      pages: {
        total: 0
      },
      dialog: false,
      ipForm:{
        ip: '',
        add: '',
        yxs: ''
      }
    }
  },
  mounted() {
    this.getIpDataList(1)
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    getIpDataList(page) {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http({
        method: 'get',
        url: '/api/visitor/getIpData/' + page
      }).then((res) =>{
        this.ipData = res.data.data
        this.pages.total = parseInt(res.data.page.totalSize)
        if (res.data.status) loading.close();
        setTimeout(()=>{loading.close()},5000)
      })
    },
    cancelForm(){
      this.ipForm.ip = ''
      this.ipForm.add = ''
      this.ipForm.yxs = ''
      this.dialog = false
    },
    addMenu(form){
      this.$confirm('是否提交' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        if (form.ip === '' || form.ip === null) {
          this.$message({
            type: 'error',
            message: 'ip地址不能为空'
          })
          return
        }
        if (form.add === '' || form.add === null) {
          this.$message({
            type: 'error',
            message: '归属地不能为空'
          })
          return
        }
        this.$http.post( '/api/visitor/addIpData', form).then((res) =>{
          if (res.data.status) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.getIpDataList(1)
            this.cancelForm()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch(()=>{
        this.$message({
          type: 'error',
          message: "取消提交"
        })
      })
    },
    getIpByPage(val){
      this.getIpDataList(val)
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>